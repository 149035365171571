
// @c an alias to /compontents
import pageHeader from '@c/pageHeader.vue'
import pageFooter from '@c/pageFooter.vue'
import productList from '@c/productList.vue'
import pageAdvert from '@c/pageAdvert.vue'
import listFilter from '@c/listFilter.vue'
import { getSeries } from '@api'
import { getCommodityList } from '@api/product'
export default {
    name: 'News',
    components: {
        pageHeader,
        pageFooter,
        productList,
        pageAdvert,
        listFilter
    },
    data() {
        return {
            seriesList: [],
            productList: [],
            total: 0,
            currentPage: 1,
            type: '0',
            seriesid: '',
            sex: 0, // 0 全部  1无2男3女
            ordertype: ''
        }
    },
    watch: {
        type(val) {
            this.getData()
        }
    },
    methods: {
        // 排序
        handleSort(val) {
            this.ordertype = this.ordertype === val ? '' : val
            this.getData()
        },
        // 筛选
        handleFilter(obj) {
            console.log('obj', obj)
            for(let key in obj) {
                console.log(key, obj[key])
                this[key] = obj[key]
            }
            console.log('this.seriesid', this.seriesid)
            console.log('this.sex', this.sex)
            this.getData()
        },
        // 点击页码
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.currentPage = val
            this.getData()
        },
        // 获取筛选下拉
        async getSeries() {
            const res = await getSeries()
            this.seriesList = res.detail
        },
        // 获取列表
        async getData() {
            const res = await getCommodityList({
                seriesid: this.seriesid,
                sex: this.sex,
                type: this.type,
                ordertype: this.ordertype,
                page: this.currentPage
            })
            this.productList = res.list.list
            this.total = res.list.count
        }
    },
    created() {
        this.getSeries()
        this.getData()
    }
}
